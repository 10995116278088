<template>
  <div class="flex w-1/2 flex-col gap-2 text-start">
    IF Lead parameters are:
    <div class="flex max-h-[336px] flex-col gap-4 overflow-auto rounded-lg bg-black-03 p-4">
      <UiLoader v-if="loading" />
      <template v-else>
        <TransitionGroup name="fade">
          <div v-for="(rule, index) in modelValue" :key="index" class="flex w-full flex-row items-center gap-3">
            <div class="flex flex-row items-center gap-2">
              <div class="flex flex-1">
                <UiInputSelect
                  :id="`rule_name${index}`"
                  v-model="rule!.rule_name"
                  :name="`rule_name${index}`"
                  :items="parameterNameItems"
                  :error="useGetFieldErrorsByIndex(v, 'rules', 'rule_name', index)"
                  @update:model-value="rule.rule_value = ''"
                ></UiInputSelect>
              </div>
              <div class="flex flex-1">
                <UiInputSelect
                  v-model="rule.rule_value"
                  :items="getParameterValues(rule.rule_name)"
                  :avatar="getIsRuleForAgents(rule)"
                  :group="getIsRuleForAgents(rule)"
                  name="name"
                  placeholder="Select option"
                  :error="useGetFieldErrorsByIndex(v, 'rules', 'rule_value', index)"
                />
              </div>
            </div>
            <div class="flex w-[100px] min-w-[100px] flex-row items-center gap-2">
              <UiInputSelect
                :id="`union_operator_${index}`"
                v-model="rule.union_operator"
                :name="`union_operator_${index}`"
                :items="unionOperatorItems"
                ghost
                :removable="false"
                text-class="text-table-header text-black-60"
                :error="useGetFieldErrorsByIndex(v, 'rules', 'union_operator', index)"
              ></UiInputSelect>
              <div class="w-4">
                <UiIcon
                  name="big-close-circle-filled"
                  size="xxs"
                  class="!cursor-pointer text-primary-100"
                  @click="removeCondition(index)"
                />
              </div>
            </div>
          </div>
        </TransitionGroup>
        <UiButtonGhost id="add-condition" @click="addCondition">
          <UiIcon name="big-add-circle" />
          Add condition
        </UiButtonGhost>
      </template>
    </div>
    <div
      v-if="v['rules'].$errors.length || v['actions'].$errors.length"
      class="flex flex-row items-start gap-2 text-error-100"
    >
      <UiIcon name="info-circle-filled" />
      <div class="text-caption-2">
        <span>Oops, Looks like you didn't complete all parameters.</span> <br />
        <span> Each rule consists of at least one full condition and outcome row</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Validation } from '@vuelidate/core'
import { PARAMETERS_NAMES_MAP } from '~/constants'
import { useUiStore } from '~/store/ui'
import type { InputItem, LeadEnrichmentRule } from '~/types'

const uiStore = useUiStore()

const modelValue = defineModel<LeadEnrichmentRule[]>()

type Props = {
  v: Validation
}
defineProps<Props>()

const loading = ref(true)
const unionOperatorItems = ref([])
const parameterNameItems = ref<InputItem[]>([])
const allParameters = ref([])
const customValues = ref({
  agentsItems: [] as InputItem[],
  languagesItems: [] as InputItem[],
  countryItems: [] as InputItem[],
})

onNuxtReady(async () => {
  await Promise.all([getOperators(), getAgents(), getLanguages(), getCountries(), getParameters()])
  loading.value = false
})

const getParameters = async () => {
  const response = await useGetRotationsParameters()
  parameterNameItems.value = Object.entries(response.data)
    .map((i) => ({
      value: i[0],
      text: PARAMETERS_NAMES_MAP.get(i[0])!,
    }))
    .sort((a, b) => b.text.localeCompare(a.text))

  allParameters.value = toRaw(response.data)
}

const AGENT_ID = 'agent_id'

const getIsRuleForAgents = (rule: LeadEnrichmentRule) => rule.rule_name === AGENT_ID

const getParameterValues = (ruleName: keyof typeof allParameters.value) => {
  const parametersWithCustomValues = {
    language_iso: 'languagesItems',
    country_iso: 'countryItems',
    [AGENT_ID]: 'agentsItems',
  }

  if (!ruleName || !allParameters.value[ruleName]) return []
  if (!parametersWithCustomValues[ruleName] && allParameters.value[ruleName]) {
    return allParameters.value[ruleName].filter((i) => i).map((i) => ({ value: i, text: i }))
  }

  return customValues.value[parametersWithCustomValues[ruleName]]
}

const getOperators = async () => {
  try {
    const response = await useGetRotationsParametersOperators()
    unionOperatorItems.value = response.data.union.map((i) => ({ value: i, text: i.toUpperCase() }))
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  }
}

const getAgents = async () => {
  customValues.value.agentsItems = await useGetUsersItems(true)
}

const getLanguages = async () => {
  const languagesList = await useLanguages()
  customValues.value.languagesItems = languagesList.map((l) => ({ text: l.name, value: l.code.toUpperCase() }))
}

const getCountries = async () => {
  const countries = await useCountries()
  customValues.value.countryItems = countries.map((c) => ({ text: c.name, value: c.iso2 }))
}

const addCondition = () => {
  // insert the first item from the parameterNameItems that is not in the array yet
  modelValue.value!.push({
    rule_name: parameterNameItems.value.find((a) => !modelValue.value!.some((m) => m.rule_name === a.value))!.value,
    rule_operator: '=',
    rule_value: '',
    order: modelValue.value!.length + 1,
    union_operator: 'and',
  })
}

const removeCondition = (index: number) => {
  modelValue.value!.splice(index, 1)
}
</script>

<style scoped></style>
